import React from 'react'
import SymptomSidebar from './SymptomSidebar'
import ReactMarkdown from "react-markdown"
import {Link} from "gatsby";


const SymptomDetailsContent = ({symptomdata}) => {


    return (
        <section className="symptoms-details-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="symptoms-details-image">
                            <img src={symptomdata.strapiSymptom.image.localFile.url}
                                 alt={symptomdata.strapiSymptom.image.alternativeText}/>
                        </div>
                        <div className="symptoms-details-content">
                            <div className="section-title">
                                <h6 className="sub-title">{symptomdata.strapiSymptom.headline_prefix}</h6>
                                <h1>{symptomdata.strapiSymptom.title}</h1>
                            </div>

                            {symptomdata.strapiSymptom.content.map((dc, idx) => {
                                const ContentText = dc.text
                                const ContentImage = process.env.API_URL + dc?.image?.url
                                const ContentImageAlt = dc?.image?.alternativeText
                                const TextImageText = dc.textimgcontent
                                const TextImageImage = process.env.API_URL + dc?.textimgimage?.url
                                const TextImageImageAlt = dc?.textimgimage?.alternativeText



                                return (

                                    <div key={idx} className={'dc-block'}>
                                        <ReactMarkdown className={'dc-text-block'} children={ContentText}/>
                                        {
                                            dc?.image?.url !== undefined && <img src={ContentImage} alt={ContentImageAlt}/>
                                        }
                                        {
                                            dc?.textimgcontent !== undefined && <div
                                                className={`textimg-block-wrapper ${dc.imgright ? 'bg-pink' : 'bg-violet'}`}>
                                                <div
                                                    className={`textimg-wrapper ${dc.imgright ? 'cal-flex-reverse' : 'cal-flex'}`}>
                                                    <div className="img-wrapper col-lg-6">
                                                        {dc?.textimgimage?.url !== undefined &&
                                                        <img src={TextImageImage} alt={TextImageImageAlt}/>}
                                                    </div>
                                                    <div className={'text-block col-lg-6'}>
                                                        <div className={'text-wrapper'}>
                                                            <ReactMarkdown>{TextImageText}</ReactMarkdown>
                                                            {dc?.url !== null && <Link to={dc?.url} target={'_blank'} className="default-btn">
                                                                {dc?.linktext}
                                                            </Link>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        }

                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">

                        <SymptomSidebar symptomdata={symptomdata}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SymptomDetailsContent
