import React from 'react'

const SymptomSidebar = ({symptomdata}) => {

    return (
        <div className="symptom-sidebar-sticky">
            <div className="symptom-sidebar-info">
                <h4>{symptomdata.strapiSymptom.symptom_sidebar_title}</h4>

                <ul>
                    {symptomdata.strapiSymptom.symptom_sidebaritem?.map((sideboarditem, idx)=>{
                        return(
                            <li className={'sidebar-single-item'} key={idx}>
                                <div className="icon">
                                    <img src={sideboarditem.icon.localFile.url} alt={sideboarditem.icon.alternativeText} />
                                </div>
                                {sideboarditem.text}
                            </li>
                        )
                    })

                    }
                </ul>
            </div>
        </div>
    )
}

export default SymptomSidebar
