import React from "react"
import {graphql} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import SymptomDetailsContent from "../components/Symptoms/SymptomDetail/SymptomDetailsContent";
import Seo from "../components/App/Seo";
import Footer from "../components/App/Footer";


const SymptomTemplate = ({data}) => {

    const {
        strapiSymptom: { title, slug, seo, single_slug_href },
        strapiSeolocalization: {symptoms_locale}
    } = data


    return (
        <Layout>
            <Seo seotags={seo} loc={single_slug_href} />
            <Navbar/>
            <PageBanner
                pageTitle={title}
                homePageText="Home"
                homePageUrl="/"
                activePageText={title}
            />
            <SymptomDetailsContent symptomdata={data} />

            <Footer />
        </Layout>
    )
}

export const query = graphql`
    query GetSingleSymptom($slug: String) {
      strapiSymptom(slug: {eq: $slug}) {
        title
        teaser
        slug
        content
        headline_prefix
        symptom_sidebar_title
        symptom_sidebaritem {
              text
              icon{
                    localFile {
                    url
               }
              }
            }
        image {
            localFile {
            url
          }
        }
        seo {
          metadescription
          metatitle
          chanoncial_link
        }
         single_slug_href {
          default_localization {
            default_url
          }
          localization {
            iso_code {
              code
            }
            url
          }
        }
      }
    strapiSeolocalization {
        symptoms_locale {
          default_localization {
            default_url
          }
          localization {
             url
             iso_code {
              code
            }
          }
        }
      }
    }
 `


export default SymptomTemplate
